import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { theme } from "common/colorScheme"
import { ButtonBase } from "components/button"
import { H3, H4, P } from "components/headings"
import Link from "components/Link"
import { Filter } from "components/ReferencesSection"
import qs from "query-string"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"
import { NumberParam, StringParam, useQueryParam } from "use-query-params"
import ArticleCard from "components/ArticleCard"
import ArticlesForm from "components/Form/ArticlesForm"
import NoResultsIllustration from "images/illustrations/No-results.svg"
import { graphql, useStaticQuery } from "gatsby"
import SearchInput from "components/SearchInput"

interface IProps {
	heading: string
	subHeading?: string
	allContentfulBlogPost: any
	perPage?: number
	contentfulForm?: any
}

interface ITag {
	name: string
	color: string
	textColor: string
}

export const formQuery = graphql`
query {
	contentfulForm(formId: { eq: "newsletter" }) {
		...Form
	}
}
`

const Header = styled.div`
	display: flex;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		justify-content: space-between;
		flex-direction: row;
	}
`

const HeaderText = styled.div`
	display: flex;
	flex-direction: column;
`

const DesktopInputContainer = styled.div`
	width: 100%;
	max-width: 380px;
	display: none;

	${mediaBreakpoint(BreakPoints.MD)} {
		display: block;
	}
`
const MobileInputContainer = styled.div`
	width: 100%;
	display: block;

	${mediaBreakpoint(BreakPoints.MD)} {
		display: none;
	}
`

const ArticlesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
		margin: -12px;
	}
`

const FiltersContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: ${theme.spacing(4)};
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: ${theme.spacing(4.25)};
	}
`

const filterStyles = css`
	flex-shrink: 0;
`

const BottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
	}
`

const ArticlesWrapper = styled.div`
	width: 100%;
`

const loadMoreStyles = css`
	display: block;
	width: initial;
	margin: 12px auto;
	min-width: 192px;
`

const loadMoreLinkStyles = css`
	text-decoration: none;
`

const NoResultsContainer = styled.div`
	margin: 6.4rem auto;
	text-align: center;
	img {
		width: 250px;
	}
`

export const normalizeTab = (name: string) => name.replace(" ", "-").toLowerCase()

export const generateTagObject = (tag: ITag) => ({
	label: tag.name,
	value: normalizeTab(tag.name),
	color: tag.color,
	textColor: tag.textColor,
})

export const Tags = [
	{ name: "Brand Awareness", color: "#DAFAC7", textColor: "#255F04" },
	{ name: "Brand Strategy", color: "#EBE6FF", textColor: "#31135E" },
	{ name: "Brand Sustainability", color: "#C2E1FB", textColor: "#115187" },
	{ name: "Brand Marketing", color: "#B4ECE9", textColor: "#024D49" },
	{ name: "Brand Deep Dives", color: "#FFD3F1", textColor: "#740952" },
	{ name: "Brand Tracking", color: "#FFD1C4", textColor: "#74220B" },
	{ name: "Consumer Insights", color: "#FFE6BA", textColor: "#81570D" },
	{ name: "Work Advice", color: "#DAFAC7", textColor: "#255F04" },
	{ name: "NewsFlash", color: "#D3D3D3", textColor: "#202020" },
].map(generateTagObject)

export const generateTagObjectFromString = (tag: string) => ({
	label: tag,
	value: normalizeTab(tag),
	color: Tags.filter(elem => elem.label === tag)[0]?.color || "#EBE6FF",
	textColor: Tags.filter(elem => elem.label === tag)[0]?.textColor || "#3c3b3e",
})

const ArticlesGrid: React.FC<IProps> = ({
	perPage = 9,
	heading,
	subHeading,
	allContentfulBlogPost,
}) => {
	const { contentfulForm } = useStaticQuery(formQuery)
	const [tagParam = "", setTagParam] = useQueryParam("tag", StringParam)
	const [searchParam = "", setSearchParam] = useQueryParam("search", StringParam)
	const [pageParam = 1, setPageParam] = useQueryParam("page", NumberParam)

	const articles = React.useMemo(() => {
		return allContentfulBlogPost.edges
			.map(article => ({
				id: article.node.id,
				slug: article.node.slug,
				title: article.node.title,
				description: article.node.shortDescription,
				heroImage: article.node.heroImage,
				tags: (article.node.tags || []).map(generateTagObjectFromString),

				publishDate: new Date(article.node.publishDate),
				authorRef: article.node.authorRef,
			}))
			.sort(({ publishDate: dateA }, { publishDate: dateB }) => dateB - dateA)
	}, [allContentfulBlogPost])

	const searchText = React.useMemo(() => {
		if (searchParam.length > 2) {
			try {
				return new RegExp(searchParam, "i")
			} catch (_e) {
				return null
			}
		} else {
			return null
		}
	}, [searchParam])

	const handleSearch = e => setSearchParam(e.target.value || undefined)

	const filtered = React.useMemo(() => {
		let foundArticles = articles
		if (searchText) {
			foundArticles = foundArticles.filter(({ title }) => searchText.test(title))
		}
		if (tagParam) {
			foundArticles = foundArticles.filter(({ tags }) => tags.some(({ value }) => value === tagParam))
		}
		return foundArticles
	}, [articles, searchText, tagParam])

	const maxPages = React.useMemo(() => Math.ceil(filtered.length / perPage), [filtered])

	const page = React.useMemo(() => {
		let pageNumber = pageParam || 1

		if (Number.isNaN(pageNumber) || pageNumber < 1) {
			pageNumber = 1
		}
		if (pageNumber > maxPages) {
			pageNumber = maxPages
		}

		return pageNumber
	}, [maxPages, pageParam])

	const displayArticles = React.useMemo(() => {
		return filtered.slice(0, page * perPage)
	}, [filtered, page])

	const displayArticlesTop = React.useMemo(() => {
		return displayArticles.slice(0, 3)
	}, [displayArticles])

	const displayArticlesBottom = React.useMemo(() => {
		return displayArticles.slice(3)
	}, [displayArticles])

	const hasMore = page < maxPages

	const nextPage = page + 1

	// for SEO
	const nextPageLink = React.useMemo(() => {
		return `/articles/?${qs.stringify({
			page: nextPage,
			search: searchParam || undefined,
			tag: tagParam || undefined,
		})}`
	}, [nextPage, searchParam, tagParam])

	const handleMore = React.useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault()
			setPageParam(page + 1)
		},
		[nextPageLink]
	)

	return (
		<>
			<Header>
				<HeaderText>
					<H3>{heading}</H3>
					{subHeading && <P>{subHeading}</P>}
				</HeaderText>
				<DesktopInputContainer>
					<SearchInput
						type="text"
						placeholder="Search Articles"
						searchParam={searchParam}
						onHandleSearch={handleSearch}
					/>
				</DesktopInputContainer>
			</Header>
			<FiltersContainer>
				<>
					{Tags.map(({ label, value, color, textColor }) => {
						const selected = tagParam === value
						return (
							<Filter
								aria-selected={selected}
								key={value}
								onClick={() => {
									setTagParam(selected ? undefined : value)
									setPageParam(null)
								}}
								css={filterStyles}
								active={selected}
								color={color}
								textColor={textColor}
							>
								{label}
							</Filter>
						)
					})}
				</>
			</FiltersContainer>
			<MobileInputContainer>
				<SearchInput
					type="text"
					placeholder="Search Articles"
					searchParam={searchParam}
					onHandleSearch={handleSearch}
				/>
			</MobileInputContainer>
			<BottomContainer>
				<ArticlesWrapper id="articles-wrapper">
					<ArticlesContainer>
						{displayArticlesTop.length ? (
							<>
								{displayArticlesTop.map(({ id, slug, title, heroImage, description, tags, authorRef }) => {
									return (
										<ArticleCard
											tags={tags}
											key={id}
											title={title}
											slug={slug}
											heroImage={heroImage}
											description={description}
											authorRef={authorRef}
										/>
									)
								})}
								{contentfulForm ? (
									<ArticlesForm
										title="Free Brand Insights and Tips"
										description="Sign up for our Newsletter to receive free, insightful tips on all things brand!"
										{...contentfulForm}
									/>
								) : null}
								{displayArticlesBottom.map(({ id, slug, title, heroImage, description, tags, authorRef }) => {
									return (
										<ArticleCard
											tags={tags}
											key={id}
											title={title}
											slug={slug}
											heroImage={heroImage}
											description={description}
											authorRef={authorRef}
										/>
									)
								})}
							</>
						) : (
							<NoResultsContainer>
								<img src={NoResultsIllustration} alt="No" />
								<H4>no article found</H4>
							</NoResultsContainer>
						)}
					</ArticlesContainer>
					{hasMore && (
						<Link css={loadMoreLinkStyles} to={nextPageLink} onClick={handleMore}>
							<ButtonBase data-variant="primary" css={loadMoreStyles}>
								Load More
							</ButtonBase>
						</Link>
					)}
				</ArticlesWrapper>
			</BottomContainer>
		</>
	)
}

export default React.memo(ArticlesGrid)
